import axios from "axios";
import router from "../../../router/index";
import store from "../../../store/index";


axios.interceptors.request.use(
  function (config) {
    const token = store.getters.token ? `Bearer ${store.getters.token}` : "";
    if (token) config.headers.Authorization = token;
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const {
      response: { status, data },
    } = err;
    if (status === 401) {
      store.commit("setToken", null);
      router.push({ name: "auth" });
    } else return Promise.reject(data);
  }
);

  // Allow automatic updating of access token
  // axios.interceptors.response.use(response => response, async (error) => {
  //   const originalRequest = error.config;
  //   debugger // eslint-disable-line

  //   if (error.response.status === 401 && !originalRequest._retry) {
  //     originalRequest._retry = true;
  //     const res = await axios.post('/auth/refresh', { token: store.getters.token });
  //     console.log(res);
  //     debugger // eslint-disable-line

  //     return axios.request(originalRequest);
  //   }
  //   return Promise.reject(error);
  // });