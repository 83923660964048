import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

import Login from "@/modules/auth/components/login";
import Register from "@/modules/auth/components/register";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/reports",
  },

  {
    path: "",
    component: {
      render(c) {
        return c("router-view");
      },
    },

    children: [
      {
        path: "reports",
        component: () => import("../modules/Reports/index"),
        children: [
          {
            path: "",
            name: "allProductsReport",
            meta: {
              sideBarValid: true,
            },
            component: () =>
              import("../modules/Reports/components/allProducts"),
          },
          {
            path: "product/:id",
            name: "productsReport",
            meta: {
              sideBarValid: true,
            },
            component: () => import("../modules/Reports/components/Product"),
          },
        ],
      },
      {
        path: "settings",
        component: () => import("../modules/Setting/index"),
        children: [
          {
            path: "",
            name: "settingProducts",
            component: () => import("../modules/Setting/components/Products"),
          },
          {
            path: "product-item/:id",
            name: "settingProductItem",
            component: () =>
              import("../modules/Setting/components/productItem"),
          },
          {
            path: "product/indication/:id",
            name: "settingIndication",
            component: () =>
              import(
                "../modules/Setting/components/productItemIndication"
              ),
          },
          {
            path: "product/infection-center/:id",
            name: "settingInfectionCenter",
            component: () =>
              import(
                "../modules/Setting/components/productItemInfectionCenter"
              ),
          },
          {
            path: "product/other-sector/:id",
            name: "settingOtherSector",
            component: () =>
              import("../modules/Setting/components/productItemOtherSector"),
          },
          {
            path: "product/hcp/:id",
            name: "settingHCP",
            component: () =>
              import("../modules/Setting/components/productItemHcp"),
          },
          {
            path: "product/ps/:id",
            name: "settingps",
            component: () =>
              import("../modules/Setting/components/productItemPs"),
          },
          {
            path: "product/treatment-line/:id",
            name: "settingTreatmentLine",
            component: () =>
              import("../modules/Setting/components/productItemTreatmentLine"),
          },
          {
            path: "product/lrfp/:id",
            name: "settingLRFP",
            component: () =>
              import("../modules/Setting/components/productItemLrfp"),
          },
          {
            path: "all-products",
            name: "settingAllProducts",
            component: () =>
              import("../modules/Setting/components/allProducts"),
          },
          {
            path: "all-products/city",
            name: "allProductsCity",
            component: () =>
              import("../modules/Setting/components/allProductsCity"),
          },
          {
            path: "all-products/payers",
            name: "allProductsPayers",
            component: () =>
              import("../modules/Setting/components/allProductsPayers"),
          },
        ],
      },
      {
        path: "/login",
        name: "auth",
        component: Login,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
      {
        path: "/register",
        name: "register",
        component: Register,
        meta: {
          noLayout: true,
          authPage: true,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;

router.beforeEach((to, from, next) => {
  if (!to.meta.authPage && !store.getters.token) next({ name: "auth" });
  if (to.meta.authPage && store.getters.token) next({ name: "allProductsReport" });
  else next();
});
