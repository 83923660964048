<template>
  <transition name="show">
    <div class="sidebar d-flex flex-column justify-space-between" v-if="open">
      <!-- <transition-group appear name="fade"> -->
      <div class="conTopLinks">
        <div class="conUserIcon">
          <div class="userRelate">
            <div class="userCircle"></div>
            <v-icon> mdi-account-circle </v-icon>
          </div>
        </div>
        <router-link
          tag="li"
          v-for="item in items"
          :key="item.id"
          class="eachLinkSidbar"
          :to="item.routeName ? { name: item.routeName } : ''"
        >
          <a>
            <v-icon>mdi-{{ item.icon }}</v-icon>
          </a>
        </router-link>
      </div>

      <div class="logoutLink text-center" @click="logout">
        <img src="@/assets/img/logout.svg" alt="" />
      </div>
      <!-- </transition-group> -->
    </div>
  </transition>
</template>

<script>
export default {
  props: ["open"],
  data: () => {
    return {
      items: [
        {
          id: 1,
          title: "",
          icon: "chart-timeline-variant",
          routeName: "allProductsReport",
        },
        {
          id: 2,

          title: "",
          icon: "cog",
          routeName: "settingProducts",
        },
      ],
    };
  },
  methods: {
    logout() {
      this.$store.commit("setToken", null);
      this.$store.commit("setUser", null);
      this.$store.commit("setIsRemember", false);
      this.$router.push({ name: "auth" });
    },
  },
};
</script>
