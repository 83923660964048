import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";

import "./assets/styles/main.scss";
import * as VueGoogleMaps from 'vue2-google-maps';
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBeQCA6ovlwTmXBssElDNMFx5rKKCBaZ7Y',
    libraries: 'places',
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
