<template>
  <div class="topbar">
    <v-app-bar class="ConatinerOfHeader" color="white" fixed>
      <v-btn icon @click="$emit('togglenav')">
        <img src="@/assets/img/menu_icon.png" alt="menu icon" />
      </v-btn>

      <v-toolbar-title class="conOfLogo">
        <img src="@/assets/img/logo.png" alt="logo" />
      </v-toolbar-title>

      <!-- <div class="conOfSearchItem">
        <v-text-field label="Solo" solo></v-text-field>
        <v-select
          v-model="formSearch.categoryId"
          :items="itemsSearch"
          item-text="title"
          item-value="id"
          label="Select Payer"
        ></v-select>
        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div> -->
      <v-spacer></v-spacer>

      <!-- <v-btn icon>
        <v-icon>mdi-magnify</v-icon>
      </v-btn> -->

      <!-- <v-menu offset-y rounded="0" max-width="300">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="bellBtnContainer"
            icon
            v-bind="attrs"
            v-on="on"
            :ripple="false"
          >
            <v-badge color="#2C77FF" content="6" overlap>
              <v-icon>mdi-bell-outline</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="400">
          <v-toolbar color="#2C77FF" dark>
            <v-toolbar-title>Notification</v-toolbar-title>
          </v-toolbar>

          <v-list subheader three-line>
            <v-list-item v-for="item in items" :key="item.id">
              <v-list-item-content>
                <v-list-item-title>Item Title</v-list-item-title>
                <v-list-item-subtitle>{{ item.title }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <v-divider></v-divider>
      </v-menu> -->
    </v-app-bar>
  </div>
</template>
<script>
export default {
  data: () => {
    return {
      formSearch: {
        categoryId: null,
      },
      itemsSearch: [
        {
          id: 1,
          title: "item 1",
        },
        {
          id: 2,
          title: "item 2",
        },
        {
          id: 3,
          title: "item 3",
        },
      ],
      items: [
        {
          id: 1,

          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 2,
          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 3,

          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 4,

          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        },
        {
          id: 4,

          title:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .theme--light.v-btn:focus::before {
    opacity: 0;
  }
}
</style>
